import React, { useMemo } from 'react';
import { TypoSBold, TypoXS, TypoXSBold, TypoXXS } from '@atoms/Typos';
import { Device } from '@components/Layout';
import useLocation from '@hooks/useLocation';
import Carousel from '@molecules/Carousel';
import routes from '@utils/routes';
import Image from 'next/image';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { SwiperSlide } from 'swiper/react';
import styles from './css';
import { GLOBAL_MARKET_PROPERTIES, KOREA_MARKET_PROPERTIES } from './data';

const CAROUSEL_DELAY = 5000;
const CAROUSEL_SPEED = 600;

const TopCarouselSection = () => {
  const { lang, t } = useTranslation('top');
  const router = useRouter();
  const { isKorea } = useLocation();
  const carouselProperties = isKorea
    ? KOREA_MARKET_PROPERTIES
    : GLOBAL_MARKET_PROPERTIES;

  const onClickProperty = (property: any) => {
    router.push({
      pathname: routes.property,
      query: { id: property.propertyId },
    });
  };

  return (
    <section className="top-carousel-wrapper">
      <div className="top-content">
        <div className="heading" lang={lang}>
          {t('carouselSection.heading')}
        </div>
      </div>
      <Carousel
        className="top-carousel"
        options={useMemo(
          () => ({
            autoplay: {
              delay: CAROUSEL_DELAY,
            },
            loop: true,
            speed: CAROUSEL_SPEED,
          }),
          []
        )}
      >
        {carouselProperties.map((property, index) => (
          <SwiperSlide
            key={property.propertyId}
            className="carousel-slide"
            onClick={() => {
              onClickProperty(property);
            }}
          >
            <Image
              alt={t(`carouselSection.${property.propertyKey}.name`)}
              data-swiper-slide-index={index}
              fill={true}
              src={`${process.env.NEXT_PUBLIC_GCS_URL}/top/carousel/${property.propertyKey}.jpg`}
              style={{ objectFit: 'cover' }}
            />
            <div className="content-wrapper">
              <div className="description">
                <Device desktop={true} tablet={true}>
                  <div className="name">
                    <TypoSBold
                      color="white"
                      text={t(
                        `carouselSection.${isKorea ? 'korea' : 'global'}.${
                          property.propertyKey
                        }.name`
                      )}
                    />
                  </div>
                  <div className="location">
                    <TypoXS
                      color="white"
                      text={t(
                        `carouselSection.${isKorea ? 'korea' : 'global'}.${
                          property.propertyKey
                        }.location`
                      )}
                    />
                  </div>
                </Device>
                <Device mobile={true}>
                  <div className="name">
                    <TypoXSBold
                      color="white"
                      text={t(
                        `carouselSection.${isKorea ? 'korea' : 'global'}.${
                          property.propertyKey
                        }.name`
                      )}
                    />
                  </div>
                  <div className="location">
                    <TypoXXS
                      color="white"
                      text={t(
                        `carouselSection.${isKorea ? 'korea' : 'global'}.${
                          property.propertyKey
                        }.location`
                      )}
                    />
                  </div>
                </Device>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Carousel>
      <style jsx={true}>{styles}</style>
    </section>
  );
};

export default TopCarouselSection;
