import COLORS from '@utils/colors';
import { BREAKPOINT } from '@utils/constants';
import css from 'styled-jsx/css';

const { mobile } = BREAKPOINT;

const styles = css`
  .banner-slider {
    padding-top: 16px;
    padding-bottom: 16px;
    &.dark {
      background-color: ${COLORS.black900};
    }
    .banner-slider-inner {
      max-width: 1280px;
      margin-right: auto;
      margin-left: auto;
      :global(.swiper) {
        display: block;
        :global(.swiper-pagination) {
          position: static;
        }
        :global(.swiper-pagination-bullets) {
          line-height: 30px;
        }
        :global(.swiper-pagination-bullet) {
          margin: 0 2px;
          width: 20px;
          height: 6px;
          background: ${COLORS.black200};
          border-radius: 3px;
          opacity: 1;
        }
        &:global(.swiper-pagination-bullet-active) {
          width: 40px;
          background: ${COLORS.black900};
        }
        :global(.swiper-button-prev),
        :global(.swiper-button-next) {
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          transition: all 0.3s;
          opacity: 1;
          @media screen and (max-width: ${mobile}px) {
            display: none;
          }
        }
        :global(.swiper-button-next) {
          right: 32px;
        }
        :global(.swiper-button-prev) {
          left: 32px;
        }
        :global(.swiper-button-prev:before),
        :global(.swiper-button-next:before) {
          height: 120px;
          top: -40px;
          opacity: 1;
          content: '';
          position: absolute;
          width: 80px;
        }
        :global(.swiper-button-prev:after),
        :global(.swiper-button-next:after) {
          content: '';
          width: 40px;
          height: 40px;
          border-radius: 40px;
          display: block;
          position: relative;
          background: ${COLORS.white};
        }
        :global(.swiper-button-prev:before) {
          left: -33px;
          background: linear-gradient(
            90deg,
            #222222 0%,
            rgba(34, 34, 34, 0) 100%
          );
        }
        :global(.swiper-button-next:before) {
          right: -33px;
          background: linear-gradient(
            270deg,
            #222222 0%,
            rgba(34, 34, 34, 0) 100%
          );
        }
      }
    }
    :global(.banner-slider-item) {
      width: 343px;
      :global(.banner-slider-image) {
        border-radius: 12px;
      }
    }
  }
`;

export default styles;
