/* eslint-disable */
import css from 'styled-jsx/css';

import COLORS from '@utils/colors';
import { BREAKPOINT } from '@utils/constants';

const mobileApplyBackground = `${process.env.NEXT_PUBLIC_GCS_URL}/top/apply-section-bg-mobile.jpg`;
const desktopApplyBackground = `${process.env.NEXT_PUBLIC_GCS_URL}/top/apply-section-bg-desktop.jpg`;

const styles = css`
  .apply-section {
    position: relative;
    height: 160px;
    margin-top: 40px;
    background-image: url(${mobileApplyBackground});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;

    @media screen and (min-width: ${BREAKPOINT.desktop}px) {
      height: 320px;
      background-image: url(${desktopApplyBackground});
    }

    .apply-now-button {
      cursor: pointer;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 48px;
      width: fit-content;
      margin: auto;
      padding: 8px 12px;
      border: 1px solid ${COLORS.white};
      border-radius: 2px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      @media screen and (min-width: ${BREAKPOINT.desktop}px) {
        bottom: 96px;
        padding: 16px 24px;
      }
    }
  }
`;

export default styles;
