/* eslint-disable */
import classNames from 'classnames';
import React, { ReactNode, useState } from 'react';

import styles from './css';

type Props = {
  selected?: number;
  modifier?: string;
  tabTitles: JSX.Element[] | string[];
  children: ReactNode[];
  onClick?: (i: number) => void;
};

const Tabs = ({
  selected = 0,
  modifier,
  tabTitles,
  children,
  onClick,
}: Props) => {
  const [selectedIndex, setSelectedIndex] = useState(selected);

  const onClickButton = (index: number) => {
    setSelectedIndex(index);
    if (onClick) onClick(index);
  };

  return (
    <div className={classNames('tabs', { [`tabs-${modifier}`]: modifier })}>
      <ul className="tab-list">
        {tabTitles.map((tabTitle, index) => (
          <li
            key={typeof tabTitle === 'object' ? tabTitle.key : tabTitle}
            className={classNames('tab-item', {
              selected: index === selectedIndex,
            })}
          >
            <button type="button" onClick={() => onClickButton(index)}>
              {tabTitle}
            </button>
          </li>
        ))}
      </ul>

      <div className="tab">{children[selectedIndex]}</div>

      <style jsx>{styles}</style>
    </div>
  );
};

export default Tabs;
