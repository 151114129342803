import React, { Children, useState } from 'react';
import Arrow from '@assets/carousel-arrow.svg';
import { TypoXS, TypoXSBold, TypoXXS, TypoXXSBold } from '@atoms/Typos';
import { Device } from '@components/Layout';
import { Autoplay, Navigation } from 'swiper/modules';
import { Swiper } from 'swiper/react';
import { SwiperOptions } from 'swiper/types';
import styles from './css';

type Props = {
  children: React.ReactNode;
  className?: string;
  options?: SwiperOptions;
};

const Carousel = ({ children, className, options = {} }: Props) => {
  const [index, setIndex] = useState(1);

  return (
    <>
      <Swiper
        className={className}
        modules={[Navigation, Autoplay]}
        navigation={{
          nextEl: '.carousel-button-next',
          prevEl: '.carousel-button-prev',
        }}
        onActiveIndexChange={(s) => setIndex(s.realIndex + 1)}
        {...options}
      >
        {children}
        <div className="content-wrapper">
          <div className="navigator-wrapper">
            <div className="navigator">
              <div className="index-wrapper">
                <Device desktop={true} tablet={true}>
                  <TypoXSBold color="black100" text={index} />
                  <TypoXS
                    color="black400"
                    text={` / ${Children.count(children)}`}
                  />
                </Device>
                <Device mobile={true}>
                  <TypoXXSBold color="black100" text={index} />
                  <TypoXXS
                    color="black400"
                    text={` / ${Children.count(children)}`}
                  />
                </Device>
              </div>
              <div className="arrow-wrapper">
                <Arrow className="arrow carousel-button-prev" />
                <Arrow className="rotate-180 arrow carousel-button-next" />
              </div>
            </div>
          </div>
        </div>
      </Swiper>
      <style jsx={true}>{styles}</style>
    </>
  );
};

export default Carousel;
