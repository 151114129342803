import React from 'react';
import TopFaq from '@components/molecules/TopFaq';
import AutoSuggestInHeader from '@molecules/AutoSuggestInHeader';
import ApplySection from '@organisms/ApplySection';
import ConvertSection from '@organisms/ConvertSection';
import FeaturedAreasSection from '@organisms/FeaturedAreasSection';
import FeaturedPropertiesSection from '@organisms/FeaturedPropertiesSection';
import LPAboutHafHSection from '@organisms/LPAboutHafHSection';
import TopCarouselSection from '@organisms/TopCarouselSection';

const TopMain = () => (
  <div>
    <TopCarouselSection />
    <AutoSuggestInHeader hasBackground={true} />
    <LPAboutHafHSection />
    <ConvertSection />
    <FeaturedPropertiesSection />
    <FeaturedAreasSection />
    <TopFaq />
    <ApplySection />
  </div>
);

export default TopMain;
