import React from 'react';
import Swiper from '@atoms/Swiper';
import Link from '@components/Link';
import { isEmpty } from '@utils/utils';
import Image from 'next/image';
import { SwiperSlide } from 'swiper/react';
import styles from './css';

type Props = {
  banners: {
    image_url: string;
    link: string;
  }[];
  mode?: 'dark';
};

const BannerSlider = ({ banners, mode }: Props) => {
  if (isEmpty(banners)) {
    return null;
  }

  let displayBanners = banners;
  const shouldLoop = displayBanners.length >= 2;

  if (shouldLoop && displayBanners.length < 5) {
    // banners are not duplicated correctly when looping with swiper ver.11
    displayBanners = [...displayBanners, ...displayBanners];
  }

  const options = {
    centerInsufficientSlides: true,
    centeredSlides: shouldLoop,
    loop: shouldLoop,
    slidesPerView: 'auto',
    spaceBetween: 8,
  } as const;

  return (
    <div className={`banner-slider ${mode}`}>
      <div className="banner-slider-inner">
        <Swiper hasSwiperButton={shouldLoop} name="banner" options={options}>
          {displayBanners.map((bannerAttribute) => (
            <SwiperSlide
              key={bannerAttribute.image_url}
              className="swiper-slide banner-slider-item"
            >
              <Link href={bannerAttribute.link}>
                <Image
                  alt={bannerAttribute.link}
                  className="banner-slider-image"
                  height={120}
                  src={bannerAttribute.image_url}
                  width={343}
                />
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default BannerSlider;
