import COLORS from '@utils/colors';
import { BREAKPOINT } from '@utils/constants';
import css from 'styled-jsx/css';

const styles = css`
  .lp-hero-section {
    position: relative;
    width: 100%;
    button {
      width: 100%;
    }

    .lp-hero-contents {
      position: absolute;
      inset: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .lp-hero-contents-text {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        margin-top: 0.5rem;

        @media screen and (min-width: ${BREAKPOINT.desktop}px) {
          margin-top: 1rem;
        }
      }

      h1,
      h2,
      h3 {
        color: ${COLORS.white};
      }

      h2 {
        text-align: center;
        font-size: 2.25rem;
        line-height: 2.5rem;
        font-weight: 500;

        @media screen and (min-width: ${BREAKPOINT.desktop}px) {
          font-size: 3rem;
          line-height: 1.25;
        }
      }

      h3 {
        font-size: 1.5rem;
        line-height: 2rem;

        @media screen and (min-width: ${BREAKPOINT.desktop}px) {
          font-size: 2.25rem;
          line-height: 2.5rem;
        }
      }

      h1 {
        position: relative;
        text-align: center;
        font-weight: 700;

        abbr {
          position: absolute;
          top: -0.75rem;
          left: 0;
          width: 100%;
          font-size: 0.875rem;
          line-height: 1.25rem;

          @media screen and (min-width: ${BREAKPOINT.desktop}px) {
            top: -1.25rem;
            font-size: 1.125rem;
            line-height: 1.75rem;
          }
        }

        div {
          font-size: 3rem;
          line-height: 1;

          @media screen and (min-width: ${BREAKPOINT.desktop}px) {
            font-size: 4.5rem;
            line-height: 1;
          }
        }
      }

      .lp-hero-button {
        margin-top: 1rem;
        width: 80%;
        max-width: 23rem;

        @media screen and (min-width: ${BREAKPOINT.desktop}px) {
          margin-top: 2rem;
        }
      }

      &.show-pronunciation {
        .lp-hero-contents-text {
          margin-top: 1.5rem;

          @media screen and (min-width: ${BREAKPOINT.desktop}px) {
            margin-top: 2rem;
          }
        }

        h2 {
          font-size: 1.25rem;
          line-height: 1.25;
          font-weight: 700;

          @media screen and (min-width: ${BREAKPOINT.desktop}px) {
            font-size: 2.25rem;
            line-height: 2.5rem;
          }
        }

        h3 {
          font-size: 1.5rem;
          line-height: 2rem;

          @media screen and (min-width: ${BREAKPOINT.desktop}px) {
            font-size: 3.75rem;
            line-height: 1;
          }
        }

        .lp-hero-button {
          margin-top: 1rem;

          @media screen and (min-width: ${BREAKPOINT.desktop}px) {
            margin-top: 2rem;
          }
        }
      }
    }
    .lp-hero-button-campaign {
      position: absolute;
      top: 75%;
      left: 50%;
      width: 60%;
      transform: translateX(-50%);
    }
  }
`;

export default styles;
