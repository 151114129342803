import React from 'react';
import Button from '@atoms/Button';
import { Device } from '@components/Layout';
import routes from '@utils/routes';
import classNames from 'classnames';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import styles from './css';

const LPHeroSection = (): JSX.Element => {
  const { lang, t } = useTranslation('lp');

  const showPronunciation = lang !== 'en';

  return (
    <section className="lp-hero-section">
      <Device desktop={true}>
        <Image
          alt="hero background"
          height={875}
          src="/images/lp/top/hero-bg-pc.png"
          style={{ height: 'auto', width: '100%' }}
          width={1920}
        />
      </Device>
      <Device mobile={true} tablet={true}>
        <Image
          alt="hero background"
          height={875}
          src="/images/lp/top/hero-bg-mobile.png"
          style={{ height: 'auto', width: '100%' }}
          width={1920}
        />
      </Device>
      <div
        className={classNames(
          'lp-hero-contents',
          showPronunciation && 'show-pronunciation'
        )}
      >
        <h2
          dangerouslySetInnerHTML={{
            __html: t('heroSection.makeTravelYourLifestyle'),
          }}
        />
        <div className="lp-hero-contents-text">
          <h3>{t('heroSection.travelSubscription')}</h3>
          <h1>
            {showPronunciation && (
              <abbr>{t('heroSection.hafhPronunciation')}</abbr>
            )}
            <div>HafH</div>
          </h1>
        </div>
        <div className="lp-hero-button">
          <Button label={t('registerNow')} link={routes.signup} size="large" />
        </div>
      </div>
      <style jsx={true}>{styles}</style>
    </section>
  );
};

export default LPHeroSection;
