/* eslint-disable */
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import React from 'react';

import SectionHeader from '@atoms/SectionHeader';

import PropertyItems from '@organisms/PropertyItems';

import { useAppSelector } from '@hooks/useAppSelector';
import routes, { route } from '@utils/routes';

import type { Property } from '@services/hafh/types/generated';

import styles from './css';

const FeaturedPropertiesSection: React.FC = () => {
  const { t } = useTranslation('top');
  const router = useRouter();
  const { featuredProperties } = useAppSelector((state) => ({
    featuredProperties: state.featuredProperties,
  }));

  const onClickProperty = (property: Property) => {
    window.open(route(routes.property, { id: property.id }));
  };

  return (
    <div className="featured-properties-section">
      <div className="featured-properties-header">
        <SectionHeader heading={t('featuredPropertiesSection.heading')} />
      </div>

      <PropertyItems
        onClickProperty={onClickProperty}
        properties={featuredProperties}
      />
      <style jsx>{styles}</style>
    </div>
  );
};

export default FeaturedPropertiesSection;
