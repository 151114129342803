/* eslint-disable */
import css from 'styled-jsx/css';

import COLORS from '@utils/colors';
import { BREAKPOINT } from '@utils/constants';

const styles = css`
  .featured-areas-section {
    max-width: 1248px;
    margin: 80px auto;
    padding: 0 120px;
    @media screen and (max-width: ${BREAKPOINT.mobile}px) {
      margin: 64px 0;
      padding: 0 16px;
    }
  }
  .featured-area-cards {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @media screen and (max-width: ${BREAKPOINT.mobile}px) {
      margin-top: 24px;
    }

    .featured-area-card {
      cursor: pointer;
      position: relative;
      background: ${COLORS.black300};
      @media screen and (min-width: ${BREAKPOINT.mobile}px) {
        width: calc((100% - 48px) / 4);
        aspect-ratio: 6 / 5;
        margin: 0 16px 16px 0;
        &:nth-child(4n) {
          margin-right: 0;
        }
        &:nth-child(n + 5) {
          margin-bottom: 0;
        }
      }
      @media screen and (max-width: ${BREAKPOINT.mobile}px) {
        width: calc((100% - 8px) / 2);
        aspect-ratio: 8 / 5;
        margin: 0 8px 8px 0;
        &:nth-child(2n) {
          margin-right: 0;
        }
        &:nth-child(n + 7) {
          margin-bottom: 0;
        }
      }
      .featured-area-card-image {
        padding-top: 56%;
      }
    }
    .title-container {
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.84) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      .featured-area-card-title {
        padding: 8px;
        text-align: left;
      }
    }
  }
`;

export default styles;
