import COLORS from '@utils/colors';
import css from 'styled-jsx/css';

const styles = css`
  :global(.dialog-container) {
    border-radius: 20px !important;

    :global(.button-container) {
      margin: 28px 0 !important;
    }

    :global(.dialog-header) {
      font-size: 20px !important;
    }

    :global(.button-link) {
      color: ${COLORS.purple500}!important;
      font-size: 16px !important;
    }
  }

  .campaign-dialog-text {
    border-bottom: solid 1px ${COLORS.black200};
    padding: 16px;

    :global(.typo) {
      color: #626365;
    }
  }
`;

export default styles;
