import React from 'react';
import { Desktop, Mobile } from '@components/Layout';
import routes from '@utils/routes';
import Image from 'next/image';
import Link from 'next/link';
import styles from './css';

const CampaignTopHeroSection = () => (
  <section className="campaign-top-hero-section">
    <div className="campaign-hero-content">
      <Desktop>
        <Image
          alt="HafH Restart hero image"
          height={475}
          src="/images/campaign/restart202408/hero-desktop.png?v=1"
          style={{
            height: 'auto',
            width: '100%',
          }}
          width={1440}
        />
      </Desktop>
      <Mobile>
        <Image
          alt="HafH Restart hero image"
          height={745}
          src="/images/campaign/restart202408/hero-mobile.png?v=1"
          style={{
            height: 'auto',
            width: '100%',
          }}
          width={745}
        />
      </Mobile>
      <div className="campaign-hero-button">
        <Link href={routes.signup}>
          <Image
            alt="登録はこちら"
            height={65}
            src="/images/campaign/restart202408/hero-button.png?v=1"
            style={{
              height: 'auto',
              width: '100%',
            }}
            width={218}
          />
        </Link>
      </div>
    </div>
    <div className="message-wrapper">
      <Desktop>
        <Image
          alt="HafHで、旅を日常に取り入れてみませんか。"
          height={432}
          src="/images/campaign/restart202408/message-desktop.png?v=1"
          style={{
            height: 'auto',
            maxWidth: 490,
            width: '100%',
          }}
          width={490}
        />
      </Desktop>
      <Mobile>
        <Image
          alt="HafHで、旅を日常に取り入れてみませんか。"
          height={480}
          src="/images/campaign/restart202408/message-mobile.png?v=1"
          style={{
            height: 'auto',
            maxWidth: 343,
            width: '100%',
          }}
          width={343}
        />
      </Mobile>
    </div>
    <style jsx={true}>{styles}</style>
  </section>
);

export default CampaignTopHeroSection;
