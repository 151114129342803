import css from 'styled-jsx/css';

const styles = css`
  .minna-no-ginko-modal {
    :global(.dialog-container) {
      overflow-y: auto !important;
      max-width: 350px !important;
    }

    :global(.dialog-header) {
      font-size: 20px !important;
    }

    :global(.button-container) {
      margin-top: 0 !important;
      margin-bottom: 24px !important;

      :global(.button-container) {
        margin-bottom: 0 !important;
      }
    }

    .dialog-content-inner {
      img {
        width: 100%;
      }
    }
  }
`;

export default styles;
