import React, { useEffect, useState } from 'react';
import Dialog from '@atoms/Dialog';
import { TypoXS } from '@atoms/Typos';
import { SHOW_DIALOG_LOCALSTORAGE_KEY } from '@utils/constants';
import Image from 'next/image';
import styles from './css';

const StopAcquisitionDialog = () => {
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    if (localStorage.getItem(SHOW_DIALOG_LOCALSTORAGE_KEY) === 'true') {
      setShowDialog(true);
      localStorage.removeItem(SHOW_DIALOG_LOCALSTORAGE_KEY);
    }
  }, []);

  const onClickClose = () => {
    setShowDialog(false);
  };

  return (
    <>
      <Dialog
        buttonAlign="vertical"
        headerText="新規登録を停止しています"
        onClickButton={onClickClose}
        show={showDialog}
      >
        <div className="campaign-dialog">
          <Image
            alt="Stop Acquisition dialog"
            height={352}
            quality={100}
            src="/images/campaign/stop-acquisition/popup.png"
            style={{
              height: 'auto',
              width: '100%',
            }}
            width={352}
          />
          <div className="campaign-dialog-text">
            <TypoXS>
              現在、新規登録の受付を一時停止しています。
              <br />
              <br />
              既存HafH会員様のサービス利用体験向上に注力しつつ、更なるアップデートを経て
              <br />
              HafH 2nd STAGEとして帰ってきます。
              <br />
              <br />
              受付再開時期等については本ページを
              <br />
              ご確認ください。
            </TypoXS>
          </div>
        </div>
      </Dialog>
      <style jsx={true}>{styles}</style>
    </>
  );
};

export default StopAcquisitionDialog;
