/* eslint-disable */
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import React from 'react';

import { TypoSBold, TypoXXXSBold } from '@components/atoms/Typos';
import { Device } from '@components/Layout';

import routes from '@utils/routes';

import styles from './css';

const ApplySection: React.FC = () => {
  const { t } = useTranslation('top');
  const router = useRouter();
  return (
    <div className="apply-section">
      <button
        className="apply-now-button"
        onClick={() => router.push(routes.signup)}
      >
        <Device mobile>
          <TypoXXXSBold text={t('applyNow')} color="white" />
        </Device>
        <Device tablet desktop>
          <TypoSBold text={t('applyNow')} color="white" />
        </Device>
      </button>
      <style jsx>{styles}</style>
    </div>
  );
};

export default ApplySection;
