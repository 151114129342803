import { BREAKPOINT } from '@utils/constants';
import css from 'styled-jsx/css';

const styles = css`
  .campaign-banners {
    padding-top: 16px;
    padding-bottom: 16px;
    .campaign-banners-title {
      text-align: center;

      @media screen and (min-width: ${BREAKPOINT.tablet}px) {
        margin-bottom: 16px;
      }
    }
    .campaign-banner {
      display: flex;
      align-items: center;
      gap: 16px;
      flex-wrap: wrap;
      justify-content: center;
      :global(img) {
        border-radius: 12px;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
`;

export default styles;
