/* eslint-disable */
import css from 'styled-jsx/css';

import COLORS from '@utils/colors';
import { BREAKPOINT } from '@utils/constants';

const styles = css`
  .content-wrapper {
    z-index: 1;
    position: absolute;
    bottom: 16px;
    width: 100%;

    @media screen and (min-width: ${BREAKPOINT.mobile}px) {
      bottom: 40px;
    }
  }

  .navigator-wrapper {
    max-width: 1248px;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    justify-content: flex-end;

    @media screen and (min-width: ${BREAKPOINT.mobile}px) {
      padding: 0 120px;
    }
  }

  .navigator {
    background: ${COLORS.black900}80;
    padding: 2px 10px;
    display: inline-flex;
    align-items: center;
    border-radius: 2px;

    @media screen and (min-width: ${BREAKPOINT.mobile}px) {
      bottom: 40px;
      right: 40px;
      padding: 8px 4px 8px 12px;
    }

    > .index-wrapper {
      display: inline-block;
    }

    > .arrow-wrapper {
      display: none;

      @media screen and (min-width: ${BREAKPOINT.mobile}px) {
        display: inline-block;
        margin-left: 4px;
      }

      > :global(.arrow) {
        cursor: pointer;
      }
    }
  }
`;

export default styles;
