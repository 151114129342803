/* eslint-disable */
import css from 'styled-jsx/css';

import COLORS from '@utils/colors';
import { BREAKPOINT } from '@utils/constants';

const styles = css`
  .top-carousel-wrapper {
    position: relative;
    > .top-content {
      position: absolute;
      top: 128px;
      left: 0;
      z-index: 2;
      width: 100%;
      pointer-events: none;
      @media screen and (min-width: ${BREAKPOINT.mobile}px) {
        top: 250px;
      }

      > .heading {
        max-width: 1248px;
        margin: 0 auto;
        padding: 0 24px;
        word-break: keep-all;
        white-space: break-spaces;
        font-size: 32px;
        color: ${COLORS.white};
        font-weight: 500;
        line-height: 48px;
        text-shadow:
          0px 2px 4px rgba(24, 39, 75, 0.12),
          0px 4px 4px rgba(24, 39, 75, 0.08);
        @media screen and (min-width: ${BREAKPOINT.mobile}px) {
          font-size: 56px;
          line-height: 125%;
          padding: 0 120px;
        }
        :lang(zh-tw) {
          font-weight: 600;
        }
      }
    }
    > :global(.top-carousel) {
      width: 100%;

      > :global(.swiper-wrapper) {
        > :global(.carousel-slide) {
          cursor: pointer;
          height: 400px;
          @media screen and (min-width: ${BREAKPOINT.mobile}px) {
            height: 640px;
          }

          > .content-wrapper {
            position: absolute;
            width: 100%;
            bottom: 16px;
            @media screen and (min-width: ${BREAKPOINT.mobile}px) {
              bottom: 40px;
            }

            > .description {
              max-width: 1248px;
              margin: 0 auto;
              padding: 0 24px;
              @media screen and (min-width: ${BREAKPOINT.mobile}px) {
                bottom: 40px;
                padding: 0 120px;
              }
            }
          }
        }
      }
    }
  }
`;

export default styles;
