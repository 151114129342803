import React, { FC, useState } from 'react';
import Dialog from '@atoms/Dialog';
import routes from '@utils/routes';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import styles from './css';
import { useQueryParams } from '@hooks/useQueryParams';

const MinnaNoGinkoModal: FC = () => {
  const { t } = useTranslation('jp-top');

  const router = useRouter();

  const { utm_source } = useQueryParams();

  const [showDialog, setShowDialog] = useState(
    () => !!utm_source?.includes('minna-no-ginko')
  );

  if (!showDialog) {
    return null;
  }

  const handleClickPrimary = () => {
    router.push(routes.signup);
  };

  return (
    <div className="minna-no-ginko-modal">
      <Dialog
        buttonAlign="vertical"
        closeButtonSize="large"
        closeLabel={t('minnaNoGinkoModal.close')}
        headerText={t('minnaNoGinkoModal.title')}
        onClickButton={() => setShowDialog(false)}
        onClickPrimaryButton={handleClickPrimary}
        primaryButtonLabel={t('minnaNoGinkoModal.button')}
        primaryButtonSize="large"
        show={showDialog}
      >
        <div className="dialog-content-inner">
          <img alt="" src="/images/lp/minna-no-ginko-ja.png" />
        </div>
      </Dialog>
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default MinnaNoGinkoModal;
