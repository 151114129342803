export const GLOBAL_MARKET_PROPERTIES = [
  {
    propertyId: 1223,
    propertyKey: 'hoshinoOsaka',
  },
  {
    propertyId: 1115,
    propertyKey: 'tokyoStation',
  },
  {
    propertyId: 1026,
    propertyKey: 'mesmTokyo',
  },
  {
    propertyId: 1537,
    propertyKey: 'indigo',
  },
  {
    propertyId: 1894,
    propertyKey: 'sofitel',
  },
  {
    propertyId: 1797,
    propertyKey: 'anantara',
  },
  {
    propertyId: 2148,
    propertyKey: 'hyattPhuket',
  },
];

export const KOREA_MARKET_PROPERTIES = [
  {
    propertyId: 1223,
    propertyKey: 'omo7Osaka',
  },
  {
    propertyId: 1266,
    propertyKey: 'cordia',
  },
  {
    propertyId: 2164,
    propertyKey: 'oriental',
  },
  {
    propertyId: 1295,
    propertyKey: 'nohgaKyoto',
  },
  {
    propertyId: 1182,
    propertyKey: 'touKyoto',
  },
  {
    propertyId: 905,
    propertyKey: 'umekoji',
  },
  {
    propertyId: 1302,
    propertyKey: 'iroha',
  },
  {
    propertyId: 2168,
    propertyKey: 'meriken',
  },
];
