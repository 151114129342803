/* eslint-disable */
import css from 'styled-jsx/css';

import { BREAKPOINT } from '@utils/constants';

const styles = css`
  .top-faq-wrapper {
    max-width: 1248px;
    margin: 80px auto;
    padding: 0 120px;
    @media screen and (max-width: ${BREAKPOINT.mobile}px) {
      margin: 64px auto;
      padding: 0 16px;
    }
    .view-all-link {
      color: black;
    }
    .top-faq {
      .top-faq-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        @media screen and (max-width: ${BREAKPOINT.mobile}px) {
          margin-bottom: 12px;
        }
      }
      .top-faq-collapse-wrapper {
        width: 100%;
      }
      .top-faq-link {
        margin-top: 32px;
      }
    }
  }
`;

export default styles;
