/* eslint-disable */
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React from 'react';

import { Desktop, Mobile } from '@components/Layout';

import SectionHeader from '@atoms/SectionHeader';
import { TypoXXSBold, TypoSBold } from '@atoms/Typos';

import * as searchActions from '@redux/modules/search';

import { useActions } from '@utils/hooks';
import routes from '@utils/routes';
import { boundsQueryFromArea } from '@utils/search-map';

import styles from './css';

const FEATURED_PREFECTURES = [
  'tokyo',
  'bali',
  'seoul',
  'osaka',
  'okinawa',
  'hawaii',
  'hokkaido',
  'hongkong',
];

const FeaturedAreasSection: React.FC = () => {
  const { t, lang } = useTranslation('top');
  const { autoComplete } = useActions({ ...searchActions });
  const router = useRouter();
  const onClickImage = async (prefecture: (typeof FEATURED_PREFECTURES)[0]) => {
    const { areas } = await autoComplete(
      t(`featuredAreasSection.${prefecture}.name`),
      lang
    );
    if (!areas || areas.length === 0) {
      return;
    }
    const area = areas[0];
    const boundsQueryObj = boundsQueryFromArea(area);

    const params = {
      area_id: area.id,
      area_name: area.name,
      order: 'popularity_desc',
      ...boundsQueryObj,
    };
    router.push({
      pathname: routes.properties,
      query: params,
    });
  };

  return (
    <div className="featured-areas-section">
      <div className="featured-areas-header">
        <SectionHeader heading={t('featuredAreasSection.heading')} />
      </div>

      <div className="featured-area-cards">
        {FEATURED_PREFECTURES.map((areaKey) => (
          <button
            key={areaKey}
            className="featured-area-card"
            onClick={() => onClickImage(areaKey)}
          >
            <div className="featured-area-card-image">
              <Image
                alt={areaKey}
                src={`${process.env.NEXT_PUBLIC_GCS_URL}/browse/featured-prefectures/${areaKey}.jpg`}
                style={{
                  objectFit: 'cover',
                }}
                fill={true}
              />
            </div>
            <div className="title-container">
              <div className="featured-area-card-title">
                <Desktop>
                  <TypoSBold
                    text={t(`featuredAreasSection.${areaKey}.displayName`)}
                    color="white"
                  />
                </Desktop>
                <Mobile>
                  <TypoXXSBold
                    text={t(`featuredAreasSection.${areaKey}.displayName`)}
                    color="white"
                  />
                </Mobile>
              </div>
            </div>
          </button>
        ))}
      </div>
      <style jsx>{styles}</style>
    </div>
  );
};

export default FeaturedAreasSection;
