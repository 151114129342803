/* eslint-disable */
import css from 'styled-jsx/css';

import { BREAKPOINT } from '@utils/constants';

const styles = css`
  .featured-properties-section {
    max-width: 1248px;
    margin: 80px auto;
    padding: 0 120px;
    @media screen and (max-width: ${BREAKPOINT.mobile}px) {
      margin: 64px 0 32px;
      padding: 0 16px;
    }
  }
`;

export default styles;
